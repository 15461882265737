import React, { useEffect, useRef } from 'react';
import he from 'he';

import * as styles from './styles.module.scss';

const RichText = (props) => {
  const { data } = props;
  const { primary } = data;
  const { html_content: htmlContent } = primary;

  const containerRef = useRef(null);

  const decodedHtml = htmlContent?.html ? he.decode(htmlContent.html) : '';

  useEffect(() => {
    if (!decodedHtml || !containerRef.current) return;

    // Insert HTML content into the container
    containerRef.current.innerHTML = decodedHtml;

    // Extract and execute scripts from the content
    const scripts = containerRef.current.querySelectorAll('script');
    scripts.forEach((script) => {
      const newScript = document.createElement('script');
      newScript.type = script.type || 'text/javascript';

      // Copy attributes from the original script
      Array.from(script.attributes).forEach((attr) => {
        newScript.setAttribute(attr.name, attr.value);
      });

      // If the script has inline content, re-add it
      if (script.textContent) {
        newScript.textContent = script.textContent;
      }

      // Replace the old script with the new one
      script.parentNode?.replaceChild(newScript, script);
    });
  }, [decodedHtml]);

  if (!decodedHtml) {
    return null;
  }

  return <div ref={containerRef} className={styles.richTextWrapper} />;
};

export default RichText;
